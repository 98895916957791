export const EXOTITLE = 'EXOCET';
export const MOBILE_BREAKPOINT = 500;
export const SCROLLDOWN_BREAKPOINT = 50;

export const HOME = 'Accueil';
export const SPONSOR = 'Commanditaires'
export const GALLERY = 'Galerie';
export const CONTACT = 'Nous rejoindre'

export const HOME_OPACITY = 0.8;
export const BASE_OPACITY = 1;